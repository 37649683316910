import React, { useState } from 'react';
import axios from 'axios'; // Importovanje Axios biblioteke
import { useNavigate } from 'react-router-dom';
import './index.css';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Slanje podataka na server za proveru
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/login`,
        {
          // Poziv funkcije za slanje zahteva na server
          username,
          password,
        }
      );

      if (response.status === 200) {
        localStorage.setItem('user', response.data.user);

        // Ako je prijava uspešna, preusmeri korisnika na glavni meni
        navigate('/main-menu'); // Izmena rutiranja na uspeh
      }
    } catch (err) {
      // Ako prijava nije uspešna, prikaži grešku
      setError('Invalid username or password');
    }
  };



  return (
    <div className="login-form">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        {error && <div className="error">{error}</div>}{' '}
        {/* Prikaži grešku ako postoji */}
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginForm;
