import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './LoginForm';
import MainMenu from './MainMenu';
import Quiz from './Quiz';
import './index.css';


export default function App() {
  const [quiz, setQuiz] = useState([]);

  const [questions, setQuestions] = useState([]);

  const fetchQuizzes = async () => {
    try {
      const res = await axios.get((`${process.env.REACT_APP_SERVER_URL}/api/v1/quizzes`));
      setQuiz(res.data.quizzes);
    } catch (error) {
      console.error(error.response);
    }
  };

  const startQuiz = (quizNumber, navigate) => {
    navigate('/');
    setQuestions(quiz[quizNumber].questions);
  };


  useEffect(() => {
    fetchQuizzes();
  }, []);

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route
            path="/main-menu"
            element={<MainMenu startQuiz={startQuiz} />}
          />
          <Route
            path="/"
            element={
              questions.length > 0 ? (
                <Quiz questions={questions} />
              ) : (
                <MainMenu startQuiz={startQuiz} />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}
