import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Quiz = ({ questions, resetStateClick }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [answered, setAnswered] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const navigate = useNavigate();

  const handleAnswerOptionClick = (isCorrect, index) => {
    if (!answered) {
      if (isCorrect) {
        setScore(score + 1);
      }
      setAnswered(true);
      setSelectedAnswer(index);
    }
  };

  const goToNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setAnswered(false);
      setSelectedAnswer(null);
    } else {
      setShowScore(true);
    }
  };

  const handleResetQuiz =()=>{
    setCurrentQuestion(0);
    setShowScore(0);
  }
  return showScore ? (
    <div className="score-section">
      <p>You scored {score} out of {questions.length}</p>
      <p className="result-message">{score >= 26 ? 'You have passed!' : 'You have failed'}</p>
      <button className="retry-button"onClick={handleResetQuiz}>Retry</button>
      <button className="main-menu-button"onClick={() => navigate('/main-menu')}>Main Menu</button>
    </div>
  ) : (
    <>
      <div className="question-section">
        <div className="question-count">
          <span>Question {currentQuestion + 1}</span>/{questions.length}
        </div>
        <div className="question-text">
          {questions[currentQuestion]?.questionText}
        </div>
      </div>
      <div className="answer-section">
        {questions[currentQuestion]?.answerOptions.map(
          (answerOption, index) => (
            <button
              key={index}
              className={
                answered
                  ? index === selectedAnswer
                    ? answerOption.isCorrect
                      ? 'correct-answer'
                      : 'incorrect-answer'
                    : ''
                  : ''
              }
              onClick={() =>
                handleAnswerOptionClick(answerOption.isCorrect, index)
              }
              disabled={answered}
            >
              {answerOption.answerText}
            </button>
          )
        )}
        {answered && <button onClick={goToNextQuestion}>Next</button>}
      </div>
    </>
  );
};

export default Quiz;
